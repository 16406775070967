<script>
    export default {
        name: "GuaranteeList",
    };
</script>

<template>
    <div class="guarantee-list">
        <div class="guarantee-list__wrap">
            <div class="guarantee-list__item">
                <div class="guarantee-list__ico">
                    <img src="/static/travel-blue/guarantee/guarantee-1.png" alt="" />
                </div>
                <div class="guarantee-list__title">
                    24-x месячная <br />
                    гарантия
                </div>
            </div>
            <div class="guarantee-list__item">
                <div class="guarantee-list__ico">
                    <img src="/static/travel-blue/guarantee/guarantee-2.png" alt="" />
                </div>
                <div class="guarantee-list__title">
                    Перерабатываемые <br />
                    материалы
                </div>
            </div>
            <div class="guarantee-list__item">
                <div class="guarantee-list__ico">
                    <img src="/static/travel-blue/guarantee/guarantee-3.png" alt="" />
                </div>
                <div class="guarantee-list__title">
                    Минимальное <br />
                    использование пластика
                </div>
            </div>
            <div class="guarantee-list__item">
                <div class="guarantee-list__ico">
                    <img src="/static/travel-blue/guarantee/guarantee-4.png" alt="" />
                </div>
                <div class="guarantee-list__title">
                    Не содержит <br />
                    опасных веществ
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .guarantee-list__wrap {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -4px;

        @include bp($bp-desktop-sm) {
            margin: 0 -12px;
        }
    }

    .guarantee-list__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(50% - 8px);
        margin: 0 4px 8px;
        background-color: #f7fcff;
        padding: 8px 0;

        @include bp($bp-desktop-sm) {
            width: calc(25% - 24px);
            padding: 32px 0;
            margin: 0 12px;
        }
    }

    .guarantee-list__ico {
        @include box(80px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0px 16px 32px rgba(10, 70, 110, 0.1);
        margin-bottom: 16px;

        img {
            max-width: 43px;
        }

        @include bp($bp-desktop-sm) {
            @include box(112px);

            img {
                max-width: 100%;
            }
        }
    }

    .guarantee-list__title {
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: var(--secondary-text);

        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 24px;
        }
    }
</style>
