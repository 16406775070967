<script>
    import { mapActions, mapGetters, mapState } from "vuex";
    import { metaInfo } from "@/core/MetaInfo";
    import BlogCard from "@tb/components/BlogCard";
    import GuaranteeList from "@tb/components/GuaranteeList";
    import Breadcrumbs from "@tb/components/Breadcrumbs";
    import Newsletter from "@tb/components/Newsletter";

    export default {
        name: "Blog",
        components: {
            BlogCard,
            GuaranteeList,
            Breadcrumbs,
            Newsletter,
        },
        computed: {
            ...mapState("Blog", ["blog", "loading"]),
            ...mapGetters("Catalog", ["mainCategories"]),
        },
        serverPrefetch() {
            return this.getBlog();
        },
        mounted() {
            if (!this.blog.length > 0) {
                this.getBlog();
            }
        },
        methods: {
            ...mapActions("Blog", ["getBlog"]),
        },
        metaInfo() {
            return metaInfo.getMeta("blog");
        },
    };
</script>

<template>
    <div class="l-container">
        <div class="blog">
            <breadcrumbs page="blog"></breadcrumbs>

            <h1 class="blog__title">Статьи и обзоры</h1>
            <div class="blog__wrapper">
                <blog-card v-for="item in blog" :blog="item" :key="item.id"></blog-card>

                <div v-if="loading" class="blog__preloader">
                    <preloader :show="loading" lg-size></preloader>
                </div>

                <div class="blog__newsletter">
                    <newsletter></newsletter>
                </div>
            </div>
            <div class="blog__guarantee">
                <div class="blog__guarantee-title">Гарантии</div>
                <guarantee-list></guarantee-list>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .blog__title {
        font-size: 30px;
        line-height: 34px;
        font-weight: 600;
        margin-bottom: 30px;
        text-align: center;

        @include bp($bp-desktop-sm) {
            font-size: 40px;
            line-height: 44px;
            margin-bottom: 60px;
        }
    }

    .blog__wrapper {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -12px 68px;

        .blog-card {
            width: calc(100% / 1 - 24px);
            margin: 0 12px 24px;

            @include bp($bp-tablet-sm) {
                width: calc(100% / 3 - 24px);
                margin-bottom: 48px;
            }

            @include bp($bp-desktop-sm) {
                width: calc(100% / 4 - 24px);
            }
        }
    }

    .blog__preloader {
        position: relative;
        width: 100%;
        height: 385px;
    }

    .blog__newsletter {
        width: 100%;
        margin: 0 12px;
    }

    .blog__guarantee {
        margin-bottom: 56px;

        @include bp($bp-desktop-sm) {
            margin-bottom: 96px;
        }
    }

    .blog__guarantee-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 16px;

        @include bp($bp-desktop-sm) {
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 32px;
        }
    }
</style>
