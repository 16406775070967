<script>
    import { apiUrl } from "@/utils";
    import TheSvgIcon from "@main/packages/TheSvgIcon";

    export default {
        name: "BlogCard",
        components: {
            TheSvgIcon,
        },
        props: {
            blog: Object,
        },
        methods: {
            apiUrl,
        },
    };
</script>

<template>
    <router-link :to="{ name: 'BlogDetail', params: { slug: blog.slug } }" class="blog-card">
        <img class="blog-card__img" :src="apiUrl(blog.previewImage)" alt="" />
        <div class="blog-card__tags"></div>
        <div class="blog-card__text">
            <div class="blog-card__title">{{ blog.name }}</div>
            <div class="blog-card__time">
                <the-svg-icon name="time_blog" :size-w="16"></the-svg-icon>
                {{ blog.readTime }}
            </div>
        </div>
    </router-link>
</template>

<style lang="scss">
    .blog-card {
        display: block;
        position: relative;
        padding-bottom: 30%;
        border-radius: 3px;
        min-height: 260px;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
        }
    }

    .blog-card__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .blog-card__text {
        width: calc(100% - 32px);
        position: absolute;
        bottom: 16px;
        left: 16px;
        color: #fff;
        z-index: 1;
    }

    .blog-card__title {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
    }

    .blog-card__time {
        display: flex;
        align-items: center;
        margin-top: 4px;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;

        svg {
            margin-right: 4px;
        }
    }
</style>
