<script>
    import FormSubscribe from "@tb/components/SubscribeForm";
    export default {
        name: "Newsletter",
        components: {
            FormSubscribe,
        },
    };
</script>

<template>
    <div class="newsletter">
        <div class="newsletter__title">
            Оставайтесь в курсе событий <br />
            с нашими ежемесячными рассылками
        </div>
        <div class="newsletter__form">
            <form-subscribe></form-subscribe>
        </div>
    </div>
</template>

<style lang="scss">
    .newsletter {
        background-color: var(--secondary);
        padding: 24px 16px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin: 0 -16px;

        @include bp($bp-desktop-sm) {
            padding: 30px 24px;
            margin: 0;
        }
    }

    .newsletter__title {
        font-weight: 600;
        font-size: 17px;
        line-height: 21px;
        margin-bottom: 16px;

        @include bp($bp-desktop-sm) {
            margin-bottom: 0;
        }
    }

    .newsletter__form {
        width: 100%;

        @include bp($bp-desktop-sm) {
            width: calc(458px + 86px + 8px);
        }

        @include bp($bp-desktop-md) {
            width: calc(458px + 129px + 8px);
        }
    }
</style>
