<script>
    import TheSvgIcon from "@main/packages/TheSvgIcon";

    export default {
        name: "Breadcrumbs",
        components: {
            TheSvgIcon,
        },
        props: {
            links: Array,
            page: String,
        },
        computed: {
            mainLink() {
                let filtered = {
                    blog: {
                        name: "Home",
                        title: "Главная",
                    },
                    product: {
                        name: "Catalog",
                        title: "Каталог",
                    },
                };
                return filtered[this.page];
            },
        },
        methods: {
            detailLink(slug) {
                let filtered = {
                    blog: {
                        name: "BlogDetail",
                        params: { slug: slug },
                    },
                    product: {
                        name: "CatalogCategory",
                        params: { slug: slug },
                    },
                };
                return filtered[this.page];
            },
        },
    };
</script>

<template>
    <ul class="b-breadcrumbs" itemscope itemtype="http://schema.org/BreadcrumbList">
        <li
            class="b-breadcrumbs__item"
            itemprop="itemListElement"
            itemscope
            itemtype="https://schema.org/ListItem"
        >
            <router-link
                :to="{ name: mainLink.name }"
                class="b-breadcrumbs__link"
                itemprop="item"
            >
                <span itemprop="name">{{ mainLink.title }}</span>
                <the-svg-icon
                    name="arrow_right"
                    is-common
                    :size-w="8"
                    :size-h="16"
                ></the-svg-icon>
                <meta itemprop="position" content="1" />
            </router-link>
        </li>
        <li
            v-if="page === 'blog'"
            class="b-breadcrumbs__item"
            itemprop="itemListElement"
            itemscope
            itemtype="https://schema.org/ListItem"
        >
            <router-link :to="{ name: 'Blog' }" class="b-breadcrumbs__link" itemprop="item">
                <span itemprop="name">Блог</span>
                <the-svg-icon
                    name="arrow_right"
                    is-common
                    :size-w="8"
                    :size-h="16"
                ></the-svg-icon>
                <meta itemprop="position" content="2" />
            </router-link>
        </li>

        <li
            v-for="(link, i) in links"
            :key="i"
            class="b-breadcrumbs__item"
            itemprop="itemListElement"
            itemscope
            itemtype="https://schema.org/ListItem"
        >
            <router-link
                :to="detailLink(link.slug)"
                :key="link.id"
                class="b-breadcrumbs__link"
                itemprop="item"
            >
                <span itemprop="name">{{ link.name }}</span>
                <the-svg-icon
                    name="arrow_right"
                    is-common
                    :size-w="8"
                    :size-h="16"
                ></the-svg-icon>
                <meta itemprop="position" :content="i + 3" />
            </router-link>
        </li>
    </ul>
</template>

<style lang="scss">
    .b-breadcrumbs {
        padding: 16px 0 20px;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;

        @include bp($bp-desktop-sm) {
            padding: 24px 0 40px;
        }
    }

    .b-breadcrumbs__item {
        &:last-child {
            .b-breadcrumbs__link {
                svg {
                    display: none;
                }
            }
        }
    }

    .b-breadcrumbs__link {
        display: flex;
        position: relative;
        font-size: 11px;
        line-height: 16px;
        color: var(--primary);

        &:hover {
            span {
                text-decoration: underline;
            }
        }

        svg {
            margin: 0 8px;
        }

        &:hover {
            text-decoration: none;
        }
    }
</style>
